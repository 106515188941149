import React from "react";
import { graphql, Link } from "gatsby";
import Layout from "../components/layout";
import Hero from "../components/hero";
import SEO from "../components/seo";
import bg from '../assets/images/news-and-updates.jpg';
import styled from 'styled-components';
import { IconContext } from "react-icons/lib";
import { AiFillTwitterSquare } from 'react-icons/ai';
import Img from "gatsby-image";
import { useTranslation } from "react-i18next";
import GetInvolved from "../components/getinvolved";
import { node } from "prop-types";

const Section = styled.div`
  padding: 100px;
  :nth-child(2) {
    padding-bottom: 20px;
  }
  :nth-child(3) {
    padding-top: 0;
  }
  h2 {
    color: #14213D;
  }
  @media (max-width: 767px) {
    padding: 100px 30px;
  }

`;

const CardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  .more-link {
    position: absolute;
    bottom: 20px;
    right: 20px;
    span {
      font-size: .75rem;
      color: #767676;
    }
    .read {
      font-size: 1rem;
      :hover {
        text-decoration: underline;
      }
    }
  }
  > a {
    text-decoration: none;
    p {
      color: #fb8524;
    }
    h3 {
      padding-top: 20px;
    }
    :hover {
      transform: translate(0, -5px)
    }
  }
  > div,
  > a {
    box-shadow: 0px 2px 7px rgba(0, 0, 0, 29%);
    border-radius: 5px;
    padding: 15px 15px 75px 15px;
    width: 32%;
    margin-bottom: 25px;
    :nth-child(3n+2) {
      margin-left: 1%;
      margin-right: 1%;
    }
    position: relative;
    @media (max-width: 1025px) {
      width: 49%;
    }
    @media (max-width: 767px) {
      width: 100%;
    }
    time {
      font-size: 16px;
      font-weight: 200;
      color: rgb(112, 112, 112);
      padding-top: 10px;
      display: block;
    }
    a {
      text-decoration: none;
      color: rgb(34, 87, 122);
      font-weight: 600;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 8px;
      :hover {
        color: #fb8524;
      }
    }
    h3 {
      color: rgb(34,87,122);
    }
  }
`

const ImageContainer = styled.div`
  .gatsby-image-wrapper {
    height: 200px;
  }
`

const CardImage = styled.img`
  width: 100%;
  height: 200px;
  object-fit: cover;
  object-position: center;
`

const CardDesc = styled.div`
  padding: 0 5px;
`

const CardText = styled.p`
  font-style: italic;
  color: rgb(51, 51, 51);
  font-weight: 400;
  padding-top: 5px;
  line-height: 1.8;
`



const NewsAndUpdatesPage = ({ data }) => {
  const { i18n } = useTranslation("common");
  return (
    <Layout>
      <SEO title="Get Involved" />
        <Hero title="News and Updates" bg={bg} />
        <Section>
          <h2>News from Our Blog:</h2>
          <CardContainer>
            {data.articles.nodes.map((node, index) => 
              <Link key={index} to={node.slug.current}>
                <div>
                  <ImageContainer>
                    <Img fluid={node.image.asset.fluid} />
                  </ImageContainer>
                  <h3>{i18n.language === "en"? node.title.en : node.title.ph}</h3>
                  <p>{node.author}</p>
                </div>
                <div className="more-link">
                  <span className="read">Read More</span>
                </div>
              </Link>
              )}
          </CardContainer>
        </Section>
        <Section>
          <h2>Updates from Our Twitter:</h2>
          <CardContainer>
              {data.tweets.edges.map(tweet =>
                tweet.node.entities.media !== null ? 
                <div key={tweet.node.id}>
                <CardImage src={tweet.node.entities.media[0].media_url_https}></CardImage> 
                  <CardDesc>
                    <time>{new Date(tweet.node.created_at).toLocaleString("en-US", { year: 'numeric', month: 'long', day: 'numeric'} )}</time>
                    <CardText>"{tweet.node.full_text}"</CardText>
                  </CardDesc>
                  <div className="more-link">
                    <IconContext.Provider value={{ className: "twitter", size: "2em" }}>
                      <a href={tweet.node.entities.media[0].url}>View on Twitter <AiFillTwitterSquare /></a>
                    </IconContext.Provider>
                      {i18n.language === "en" ? <span>English Translations can be found on Twitter</span> : null}
                  </div>
                </div> :
                null
              )}
          </CardContainer>
        </Section>
        <GetInvolved />
    </Layout>
  )
}

export const query = graphql`
 query MyQuery {
  articles: allSanityArticles {
    nodes {
      author
      title {
        en
        ph
      }
      slug {
        current
      }
      image {
        asset {
          fluid {
              ...GatsbySanityImageFluid
            }
        }
      }
    }
  }
  tweets: allTwitterStatusesUserTimelineAapaqueries {
      edges {
        node {
          created_at
          full_text
          id_str
          entities {
            media {
              id
              media_url_https
              url
            }
          }
          id
          display_text_range
        }
      }
    }
  }
`

export default NewsAndUpdatesPage
