import React from 'react';
import { StaticQuery, graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import { useTranslation } from "react-i18next";
import styled from 'styled-components';

const GetInvolvedStyles = styled.div`
    background-color: #FFCE7E;
    padding: 50px;
    color: #22577A;
    a {
        text-decoration: none;
        color: #22577A;
    }

    a:hover {
        opacity: 50%;
    }

    h3 {
        text-align: center;
        font-size: 3.5rem;
    }
    .involved-details {
        display: flex;
        justify-content: space-evenly;
        align-items: flex-end;
        text-align: center;
        @media (max-width: 425px) {
            flex-wrap: wrap;
            gap: 20px;
        }
        h4 {
            text-transform: uppercase;
            font-size: 2rem;
        }
    }
`;

export default function GetInvolved() {
    const { i18n } = useTranslation("common")
    return (
        <StaticQuery query={graphql`
            query InvolvedQuery {
            allFile(filter: {relativeDirectory: {eq: "icons"}}) {
                nodes {
                childImageSharp {
                    id
                    fixed {
                    ...GatsbyImageSharpFixed
                    }
                    parent {
                        ... on File {
                            name
                        }
                    }
                }
                }
            }
            }
        `}
        render={( data ) =>(
            <GetInvolvedStyles>
                <h3>Get Involved</h3>
                <div className="involved-details">
                    {data.allFile.nodes.map((node) => 
                        <Link to={"/"+ i18n.language + "/get-involved"} key={node.childImageSharp.id} className="details-wrap">
                            <Img fixed={node.childImageSharp.fixed} alt={node.childImageSharp.parent.name.replace(/-/g, " ")} />
                            <h4>{node.childImageSharp.parent.name.replace(/-/g, " ")}</h4>
                        </Link>
                    )}
                </div>
            </GetInvolvedStyles>
        )}
        />
    )
} 